import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import Seo from "../../components/Seo"
import Layout from "../../components/Layout"
import ProfileHero from "../../components/ProfileHero"
import Breadcrumbs from "../../components/Breadcrumbs"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"

const CompanyProfile = ({ location, contactEmail, msPerksBenefitsJSONData, msPerksBenefitsStringData, rolesTags }) => {
  const [data, setMemberData] = useState("")

  useEffect(() => {
    if (window.MemberStack.onReady) {
      window.MemberStack.onReady
        .then(function (member) {
          setMemberData(member)
        })
        .catch((e) => {
          console.log(e)
        })
    }
  }, [])

  const { crumbs } = useBreadcrumb({
    location,
    crumbLabel: "Candidate Profile",
  })

  if (data) {
    if (data["contact-email"]) {
      contactEmail = (
        <li>
          <a href={`mailto:${data["contact-email"]}`} className="button button--primary">
            Contact
          </a>
        </li>
      )
    }
  }

  const candidateProfileInfo = [
    { label: "Email", value: data["contact-email"], icon: "fas fa-envelope" },
    { label: "Phone", value: data["phone-number"], icon: "fas fa-phone-alt" },
    { label: "Years of Experience", value: data["years-experience-in-tech"], icon: "fas fa-calendar-alt" },
    { label: "Portfolio", value: data["portfolio-github-url"], icon: "fas fa-address-card" },
    { label: "Other Profiles", value: data["linkedin-or-other-profile"], icon: "fas fa-briefcase" },
  ]

  if (data) {
    if (data["roles-seeking"]) {
      msPerksBenefitsStringData = data["roles-seeking"]
      msPerksBenefitsJSONData = JSON.parse(msPerksBenefitsStringData)
    }
  }
  if (msPerksBenefitsJSONData) {
    if (msPerksBenefitsJSONData.length > 0) {
      rolesTags = (
        <div className="tags--wrapper">
          <h4>Roles seeking</h4>
          <div className="tags--container">
            {msPerksBenefitsJSONData?.map((item, index) => {
              return (
                <span className={item.categoryClass} key={index}>
                  {item.value}
                </span>
              )
            })}
          </div>
        </div>
      )
    }
  }
  return (
    <Layout>
      <Seo title="Member Profile" />
      <Breadcrumbs crumbs={crumbs} />
      <ProfileHero profile={data} />

      <div className="profiles container">
        <div className="row profile__layout">
          <div className="sidebar">
            <ul>
              {contactEmail}
              <li>
                <Link to="/candidate/member-profile-edit" className="button button--secondary">
                  Edit Profile
                </Link>
              </li>
            </ul>
          </div>
          <div className="content">
            <div className="content__header">
              <h1>
                {data["first-name"]} {data["last-name"]}!
              </h1>
            </div>
            <div className="content__description">
              <h2>Profile Information</h2>
              <div className="profiles__forms">
                <div className="profiles__forms--cols">
                  <ul className="candidate--meta">
                    {candidateProfileInfo.map((item, index) => {
                      if (item.value) {
                        if (item.label === "Email")
                          return (
                            <li key={index}>
                              <h4>{item.label}</h4>{" "}
                              <span>
                                <i className={item.icon}></i>{" "}
                                <a href={`mailto:${item.value}`} target="_blank">
                                  {item.value}
                                </a>
                              </span>
                            </li>
                          )
                      }
                      if (item.value) {
                        if (item.label === "Phone") {
                          return (
                            <li key={index}>
                              <h4>{item.label}</h4>{" "}
                              <span>
                                <i className={item.icon}></i>{" "}
                                <a href={`tel:${item.value}`} target="_blank">
                                  {item.value}
                                </a>
                              </span>
                            </li>
                          )
                        }
                      }
                      if (item.value) {
                        if (item.label === "Years of Experience") {
                          return (
                            <li key={index}>
                              <h4>{item.label}</h4>{" "}
                              <span>
                                <i className={item.icon}></i> {item.value}
                              </span>
                            </li>
                          )
                        }
                      }
                      if (item.value) {
                        if (item.label === "Portfolio" || item.label === "Other Profiles") {
                          return (
                            <li key={index}>
                              <h4>{item.label}</h4>{" "}
                              <span>
                                <i className={item.icon}></i>{" "}
                                <a href={item.value} target="_blank">
                                  {item.value}
                                </a>
                              </span>
                            </li>
                          )
                        }
                      }
                    })}
                  </ul>{" "}
                </div>
                <div className="profiles__forms--cols">{rolesTags}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CompanyProfile
